.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #140B00;
}

.wrapper > img {
  width: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  padding: 4px 62px;
}

.contentWrapper h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFF3E4;
  margin-bottom: 32px;
}

.supportList {
  max-width: 1100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -16px -28px;
  align-items: center;
}

.supportList img {
  margin: 16px 28px;
  max-height: 87px;
  max-width: 180px;
}

.wrapper .mobileImg {
  display: none;
}

@media (max-width: 500px) {
  .contentWrapper {
    padding-inline: 16px;
  }
  .contentWrapper h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 2px;
  }
  .wrapper > img:not(.mobileImg) {
    display: none;
  }
  .wrapper .mobileImg {
    display: block;
  }

  .supportList img {
    zoom: .7;
    margin: 24px 0;
  }
  .supportList {
    margin: 0;
  }
  .supportList a {
    display: flex;
  }
  .supportList img {
    margin: 0;
  }
}
