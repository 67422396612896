.wrapper {
  padding: 70px;
  background: #140B00;
}

.wrapper > h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.wrapper .subscribe {
  width: 662px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 32px auto 0;
}

.wrapper .subscribe input {
  padding: 30px 24px;
  background: rgba(255, 243, 228, 0.2);
  border-radius: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: rgba(255, 243, 228, 0.9);
  border: none;
}

.wrapper .subscribe button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 277px;
  height: 75px;
}

@media (max-width: 800px) {
  .wrapper .subscribe {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .wrapper {
    padding-inline: 16px;
  }
}


@media (max-width: 650px) {
  .wrapper {
    padding-inline: 16px;
  }
}


@media (max-width: 600px) {
  .wrapper .subscribe {
    display: flex;
  }

  .wrapper .subscribe button {
    position: relative;
    top: unset;
    right: unset;
    transform: unset;
    margin: 24px auto 0;
  }
}

