.earnMoreWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
  grid-gap: 32px;
}

.earnMoreItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  padding: 0 62px 64px;
}

.earnMoreItem .description {
  margin-block: 42px;
}

.earnMoreItem .price {
  margin-top: 8px;
}

.earnMoreItem h3 {
  min-height: 100px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.earnMoreItem > div + div {
  margin-top: 32px;
}

.earnMoreItem .descriptionRow img {
  margin-right: 12px;
  height: max-content;
}

.earnMoreItem > img {
  width: 70%;
  object-fit: cover;
}

.earnMoreItem a {
  margin-top: auto;
  max-width: 277px;
  width: 100%;
  height: 75px;
}

@media (max-width: 785px) {
  .earnMoreItem > img {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .earnMoreItem > img {
    width: 100%;
  }

  .earnMoreItem {
    padding-inline: 16px;
  }
}
