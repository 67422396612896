.wrapper {
  height: calc(100vh - 86px - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 72px 40px 72px;

  background-image: url("../../../assets/image/BATTLE_ARENA_BG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #140B00;
}

.wrapper h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFF3E4;
  max-width: 692px;
}

.wrapper p {
  font-family: 'Poppins';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFF3E4;
  margin-top: 8px;
  max-width: 476px;
  margin-inline: auto;
}

.wrapper button {
  padding: 24px 26px;
  margin: 8px auto 0;
  display: block;
}

.statistics {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(20, 11, 0, 0) 1.47%, #140B00 71.43%);
  margin-top: -100px;
  padding: 0 72px 40px;
}

.statistics button {
  height: 100px;
  width: 215px;
  cursor: auto;
}

.buttonContent {
  display: flex;
  flex-direction: column;
}

.buttonContent span:not(.subtitle) {
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.buttonContent .subtitle {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #FFF3E4;
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .statistics {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 32px;
    margin-top: -232px;
  }
}

@media (max-width: 1050px) {
  .wrapper {
    padding: 60px 72px 40px 46px;
  }
}

@media (max-height: 750px) {
  .statistics {
    background-color: #140B00;
    margin-top: 0;
    grid-template-columns: 1fr;
    justify-items: center;
    padding-top: 52px;
  }
  .wrapper {
    background-image: url("../../../assets/image/BATTLE_ARENA_BG_MOBILE.png");
    padding: 138px 16px 24px;
    background-size: cover;
    height: max-content;
  }

  .wrapper h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  }

  .wrapper p {
    font-size: 16px;
    line-height: 27px;
    text-align: start;
    margin-top: 24px;
  }

  .wrapper > button {
    margin-top: 24px;
  }

  .statistics {
    margin-top: -2px;
    background-color: #140B00;
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

@media (max-height: 850px) and (max-width: 730px) {
  .statistics {
    background-color: #140B00;
    margin-top: 0;
    grid-template-columns: 1fr;
    justify-items: center;
    padding-top: 52px;
  }
  .wrapper {
    background-image: url("../../../assets/image/BATTLE_ARENA_BG_MOBILE.png");
    background-size: contain;
    padding: 138px 16px 24px;
    height: max-content;
  }

  .wrapper h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  }

  .wrapper p {
    font-size: 16px;
    line-height: 27px;
    text-align: start;
    margin-top: 24px;
  }

  .wrapper > button {
    margin-top: 24px;
  }

  .statistics {
    margin-top: -2px;
    background-color: #140B00;
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

@media (max-width: 500px) {
  .wrapper {
    background-image: url("../../../assets/image/BATTLE_ARENA_BG_MOBILE.png");
    background-size: contain;
    padding: 138px 16px 24px;
    height: calc(100vh - 48px);
  }

  .wrapper h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  }

  .wrapper p {
    font-size: 16px;
    line-height: 27px;
    text-align: start;
    margin-top: 24px;
  }

  .wrapper > button {
    margin-block: auto;
  }

  .statistics {
    background-color: #140B00;
    grid-template-columns: 1fr;
    justify-items: center;
    margin-top: -2px;
  }
}
