.headerWrapper {
  height: 89px;
  background: #140B00;
  backdrop-filter: blur(28.5px);
  position: fixed;
  inset: 0 0 auto 0;
  padding: 12px 56px;
  display: flex;
  align-items: center;
  z-index: 100;
  transition: .3s;
}

.mediaLinks {
  display: flex;
  min-width: 180px;
}

.mediaLinks > *,
.social > * {
  cursor: pointer;
}

.mediaLinks > * + * {
  margin-left: 25px;
}

.headerWrapper .navigation {
  display: flex;
  margin-inline: 56px 80px;
  width: 100%;
}

.navigation a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #FFF3E4;
}

.navigation > img {
  position: absolute;
  top: 19px;
  left: 50%;
  width: 124px;
  transform: translateX(-50%);
}

.headerWrapper  .navigation > a + a {
  margin-left: 36px;
}

.headerWrapper > a button {
  padding: 22px 20px;
  white-space: nowrap;
}

.mobileHeaderWrapper {
  display: none;
}

.burgerMenu > div {
  width: 24px;
  height: 2px;
  background: #FFF3E4;
  margin-bottom: 4px;
}

.burgerMenuContent {
  display: none;
}

@media (max-width: 1250px) {
  .headerWrapper {
    padding: 12px 24px;
  }

  .headerWrapper .navigation {
    margin-inline: 24px 40px;
  }

  .headerWrapper .navigation > a + a {
    margin-left: 24px;
  }

  .headerWrapper > a button {
    padding: 22px 16px;
  }
}

@media (max-width: 1050px) {
  .headerWrapper.activeMenu {
    background-color: transparent;
    backdrop-filter: unset
  }
  .headerWrapper .navigation {
    display: none;
  }

  .headerWrapper > a button {
    display: none;
  }

  .mediaLinks {
    display: none;
  }

  .headerWrapper {
    padding: 12px 24px;
    height: 48px;
  }

  .mobileHeaderWrapper > img {
    position: absolute;
    top: 13px;
    left: 50%;
    z-index: 100;
    height: 97px;
    transform: translateX(-50%);
  }

  .burgerMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    z-index: 100;
  }

  .burgerMenu > * {
    transition: .3s;
  }
  .burgerMenu > * + * {
    margin-top: 5px;
  }

  .mobileHeaderWrapper {
    display: flex;
    align-items: center;
    width: 200%;
  }

  .burgerMenuContent {
    position: fixed;
    inset: 0;
    background: #140B00;
    backdrop-filter: blur(28.5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;
    transform: translateX(100%);
    transition: .3s;
    background: url("../../assets/image/burger-content-bg.png") no-repeat center;
    background-size: cover;
  }

  .activeMenu.burgerMenuContent {
    transform: translateX(0);
  }

/*  active styles for burger menu with 2 lines to make an x form*/
  .activeMenu .burgerMenu > *:nth-child(1) {
    transform: rotate(45deg) translateY(6px);
  }

  .activeMenu .burgerMenu > *:nth-child(2) {
    transform: rotate(-45deg) translateY(-6px);
  }

  .activeMenu .burgerMenu > * + * {
    margin-top: 0;
  }

  .burgerMenuContent > a button {
    width: 320px;
    height: 86px;
    margin-top: 150px;
  }

  .burgerMenuContent .navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 63px;
  }

  .burgerMenuContent .navigation a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    color: #FFF3E4;
    margin-top: 32px;
  }

  .burgerMenuContent .social {
    max-width: 200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 52px;
  }
}
