@font-face {
  font-family: "GROBOLD";
  src: local("GROBOLD"),
  url("../assets/fonts/GROBOLD.ttf") format("truetype");
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  margin-block: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html,
body {
  line-height: 1;
  background-color: #140B00;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  --color-base: #000000;
  --color-primary: #18A800;
  --color-error: #F05C44;
  --color-error-light: rgba(230, 70, 70, 0.1);
  --color-error-light: #FAEBEB;
  --color-gray-50: #EBEDF0;
  --color-gray-100: #E1E3E6;
  --color-gray-200: #F9F9F9;
  --color-gray-500: #76787A;
  --color-gray-550: #5D5F61;
  --color-gray-600: #2C2D2E;
  --color-border: #e6e6e6;
  --bg-main: #F8F8FB;
  --font-family-base: 'GROBOLD', sans-serif;
  --border-radius: 4px;
  --zIndex-overlay: 1000;
  --zIndex-modal: 1010;
  --zIndex-backdrop: 1020;
  --zIndex-menu: 1030;
}

html, body, #root {
  height: 100%;
}

body {
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-base);
  background-color: var(--bg-main);
}

button {
  cursor: pointer;
}

body, span, button, input, h1, h2, h3, h4, h5 {
  font-family: var(--font-family-base);
}

.landing-wrapper {
  margin-top: 86px;
}

@media (min-width: 992px) {
  body {
    font-size: 16px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  color: var(--color-primary);
  text-decoration: none;
}

svg {
  fill: currentColor;
}

@media (max-width: 1050px) {
  .landing-wrapper {
    margin-top: 45px;
  }
}
