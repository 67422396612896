.wrapper {
  display: flex;
  flex-direction: column;
  padding: 117px 62px 72px;
  background: url("../../../assets/image/hero-forest-bg.png"), #140B00;
  background-position: center;
  background-size: cover;
}

.headline {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  align-items: center;
  margin-bottom: 80px;
}

.headline h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.headline p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #FFF3E4;
  opacity: 0.6;
}

.characterView {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 64px;
}

.gearSelection {
  display: grid;
  grid-template-columns: 97px minmax(0, 1fr);
  grid-gap: 12px;
}

.bodyPartSelection {
  display: flex;
  flex-direction: column;
}

.bodyPartSelection > * + * {
  margin-top: 16px;
}

.bodyPartSelection .bodyPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10.5px 24px;
  width: 97px;
  height: 97px;
  cursor: pointer;
  position: relative;
}

.bodyPartSelection .bodyPart > img:not(.bg) {
  width: 49px;
  height: 49px;
}

.itemSelection .item .bg,
.bodyPartSelection .bodyPart .bg {
  position: absolute;
  inset: 0;
  object-fit: cover;
  transition: .3s;
  opacity: .5;
}

.bodyPartSelection .bodyPart span,
.bodyPartSelection .bodyPart img:not(.bg) {
  mix-blend-mode: overlay;
  transition: .3s;
}

.itemSelection .item .color {
  border: 4px white solid;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-top: -12px;
  margin-right: -6px;
  position: relative;
}

.bodyPart span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #FFF3E4;
  white-space: nowrap;
}

.itemSelection .item.activeItem .bg,
.bodyPartSelection .bodyPart.activeBodyPart .bg {
  opacity: 1;
}

.bodyPartSelection .bodyPart.activeBodyPart img:not(.bg),
.bodyPartSelection .bodyPart.activeBodyPart span {
  z-index: 1;
  mix-blend-mode: unset;
}

.activeBodyPart span {
  color: white;
}

.bodyItemsWrapper {
  display: flex;
  flex-direction: column;
}

.levelSelection {
  display: flex;
  gap: 24px;
  margin-bottom: 12px;
  overflow: auto;
}

.level {
  height: 75px;
  min-width: 161px;
  background-size: cover;
  opacity: 0.4;
  transition: opacity .3s;
}

.activeLevel {
  opacity: 1;
}

.itemSelection {
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  grid-auto-rows: 155px;
  grid-gap: 18px 24px;
  height: 600px;
  overflow: auto;
}

.itemSelection .item {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemSelection .item .itemIcon {
  position: absolute;
  padding-bottom: 24px;
  z-index: 1;
  width: 100%;
  height: 100%;
}


.previewCharacter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.previewCharacter button {
  width: 277px;
  height: 75px;
  margin-top: 45px;
}

.editView {
  margin-bottom: 45px;
  display: flex;
  align-items: center;
}

.editView > span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #FFF3E4;
}

.editView label {
  margin-inline: 24px;
}

.character {
  margin-top: 92px;
  width: 100%;
  max-width: 452px;
  height: 452px;
  position: relative;
}

.character .color {
  position: absolute;
  z-index: 2;
  inset: 0;
  border-radius: 20px;
  transition: .3s background-color;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.character .eyes,
.character .facial_hair,
.character .eyebrows,
.character .hair,
.character .clothes,
.character .extra,
.character .head,
.character .hero {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 64px);
  object-fit: contain;
  transform: translate(-35%, -35%);
  scale: 1.4;
}

.character .hero {
  z-index: 3;
}

.character .eyes,
.character .facial_hair,
.character .eyebrows,
.character .hair,
.character .clothes,
.character .extra,
.character .head {
  z-index: 4;
}

.character .facial_hair {
  z-index: 5;
}

.character .hair {
  z-index: 6;
}

.gearSelection > button {
  display: none;
}

@media (max-width: 1100px) {
  .headline {
    grid-template-columns: minmax(0, 1fr);
  }

  .characterView {
    grid-template-columns: minmax(0, 1fr);
  }

  .previewCharacter {
    grid-row: 1;
  }

  .character {
    width: 50%;
    padding-bottom: 50%;
  }

  .gearSelection > button {
    display: block;
    grid-column: 1/-1;
    width: 277px;
    height: 75px;
    margin: 36px auto 0;
  }

  .previewCharacter > button {
    display: none;
  }
}

@media (max-width: 650px) {
  .wrapper {
    padding: 117px 16px 72px;
  }

  .characterView {
    grid-gap: 40px;
  }

  .gearSelection {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 32px;
  }

  .bodyPartSelection {
    flex-direction: row;
    overflow: auto;
    margin-right: -16px;
    max-width: calc(100vw - 32px);
  }

  .character {
    width: 75%;
    padding-bottom: 75%;
  }

  .bodyPart {
    margin-top: 0;
    margin-right: 16px;
  }

  .itemColorSelection {
    margin-inline: 0;
    overflow: auto;
    max-width: calc(100vw - 32px);
  }

  .itemSelection {
    display: flex;
    overflow: auto;
    width: 100%;
    height: 170px;
    max-width: calc(100vw - 32px);
  }
  .itemSelection .item {
    min-width: 163px;
  }
}


@media (max-width: 500px) {
  .character {
    width: 100%;
    padding-bottom: 91%;
  }

  .headline h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 2px;
  }

  .headline p {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #FFF3E4;
  }
}
