@font-face {
  font-family: "GROBOLD";
  src: local("GROBOLD"),
  url(/static/media/GROBOLD.9145f1dd.ttf) format("truetype");
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  margin-block: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html,
body {
  line-height: 1;
  background-color: #140B00;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  --color-base: #000000;
  --color-primary: #18A800;
  --color-error: #F05C44;
  --color-error-light: rgba(230, 70, 70, 0.1);
  --color-error-light: #FAEBEB;
  --color-gray-50: #EBEDF0;
  --color-gray-100: #E1E3E6;
  --color-gray-200: #F9F9F9;
  --color-gray-500: #76787A;
  --color-gray-550: #5D5F61;
  --color-gray-600: #2C2D2E;
  --color-border: #e6e6e6;
  --bg-main: #F8F8FB;
  --font-family-base: 'GROBOLD', sans-serif;
  --border-radius: 4px;
  --zIndex-overlay: 1000;
  --zIndex-modal: 1010;
  --zIndex-backdrop: 1020;
  --zIndex-menu: 1030;
}

html, body, #root {
  height: 100%;
}

body {
  font-size: 14px;
  line-height: 1.5;
  color: #000000;
  color: var(--color-base);
  background-color: #F8F8FB;
  background-color: var(--bg-main);
}

button {
  cursor: pointer;
}

body, span, button, input, h1, h2, h3, h4, h5 {
  font-family: 'GROBOLD', sans-serif;
  font-family: var(--font-family-base);
}

.landing-wrapper {
  margin-top: 86px;
}

@media (min-width: 992px) {
  body {
    font-size: 16px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  color: #18A800;
  color: var(--color-primary);
  text-decoration: none;
}

svg {
  fill: currentColor;
}

@media (max-width: 1050px) {
  .landing-wrapper {
    margin-top: 45px;
  }
}

.Default_root__3QHC1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #ffffff;
  z-index: 100;
}

.Default_spinner__3xcRV {
  width: 50px;
  height: 50px;
  z-index: 2;
  -webkit-animation: Default_rotate__n7zA9 2s linear infinite;
          animation: Default_rotate__n7zA9 2s linear infinite;
}

.Default_spinner__3xcRV circle {
  stroke: var(--color-gray-500);
  stroke-linecap: round;
  -webkit-animation: Default_dash__2UraS 1.5s ease-in-out infinite;
          animation: Default_dash__2UraS 1.5s ease-in-out infinite;
}

@-webkit-keyframes Default_rotate__n7zA9 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Default_rotate__n7zA9 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes Default_dash__2UraS {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes Default_dash__2UraS {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.landing-hero_wrapper__2Talh {
  height: calc(100vh - 86px - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 72px 40px 72px;

  background-image: url(/static/media/BATTLE_ARENA_BG.c92ab24a.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #140B00;
}

.landing-hero_wrapper__2Talh h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFF3E4;
  max-width: 692px;
}

.landing-hero_wrapper__2Talh p {
  font-family: 'Poppins';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFF3E4;
  margin-top: 8px;
  max-width: 476px;
  margin-inline: auto;
}

.landing-hero_wrapper__2Talh button {
  padding: 24px 26px;
  margin: 8px auto 0;
  display: block;
}

.landing-hero_statistics__3f2k3 {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(20, 11, 0, 0) 1.47%, #140B00 71.43%);
  margin-top: -100px;
  padding: 0 72px 40px;
}

.landing-hero_statistics__3f2k3 button {
  height: 100px;
  width: 215px;
  cursor: auto;
}

.landing-hero_buttonContent__3HD1f {
  display: flex;
  flex-direction: column;
}

.landing-hero_buttonContent__3HD1f span:not(.landing-hero_subtitle__1CPzl) {
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.landing-hero_buttonContent__3HD1f .landing-hero_subtitle__1CPzl {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #FFF3E4;
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .landing-hero_statistics__3f2k3 {
    display: grid;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    grid-gap: 32px;
    margin-top: -232px;
  }
}

@media (max-width: 1050px) {
  .landing-hero_wrapper__2Talh {
    padding: 60px 72px 40px 46px;
  }
}

@media (max-height: 750px) {
  .landing-hero_statistics__3f2k3 {
    background-color: #140B00;
    margin-top: 0;
    grid-template-columns: 1fr;
    justify-items: center;
    padding-top: 52px;
  }
  .landing-hero_wrapper__2Talh {
    background-image: url(/static/media/BATTLE_ARENA_BG_MOBILE.29602f24.png);
    padding: 138px 16px 24px;
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
  }

  .landing-hero_wrapper__2Talh h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  }

  .landing-hero_wrapper__2Talh p {
    font-size: 16px;
    line-height: 27px;
    text-align: start;
    margin-top: 24px;
  }

  .landing-hero_wrapper__2Talh > button {
    margin-top: 24px;
  }

  .landing-hero_statistics__3f2k3 {
    margin-top: -2px;
    background-color: #140B00;
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

@media (max-height: 850px) and (max-width: 730px) {
  .landing-hero_statistics__3f2k3 {
    background-color: #140B00;
    margin-top: 0;
    grid-template-columns: 1fr;
    justify-items: center;
    padding-top: 52px;
  }
  .landing-hero_wrapper__2Talh {
    background-image: url(/static/media/BATTLE_ARENA_BG_MOBILE.29602f24.png);
    background-size: contain;
    padding: 138px 16px 24px;
    height: -webkit-max-content;
    height: max-content;
  }

  .landing-hero_wrapper__2Talh h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  }

  .landing-hero_wrapper__2Talh p {
    font-size: 16px;
    line-height: 27px;
    text-align: start;
    margin-top: 24px;
  }

  .landing-hero_wrapper__2Talh > button {
    margin-top: 24px;
  }

  .landing-hero_statistics__3f2k3 {
    margin-top: -2px;
    background-color: #140B00;
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

@media (max-width: 500px) {
  .landing-hero_wrapper__2Talh {
    background-image: url(/static/media/BATTLE_ARENA_BG_MOBILE.29602f24.png);
    background-size: contain;
    padding: 138px 16px 24px;
    height: calc(100vh - 48px);
  }

  .landing-hero_wrapper__2Talh h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  }

  .landing-hero_wrapper__2Talh p {
    font-size: 16px;
    line-height: 27px;
    text-align: start;
    margin-top: 24px;
  }

  .landing-hero_wrapper__2Talh > button {
    margin-block: auto;
  }

  .landing-hero_statistics__3f2k3 {
    background-color: #140B00;
    grid-template-columns: 1fr;
    justify-items: center;
    margin-top: -2px;
  }
}

.cartoon-button_cartoonButton__CPQUc {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.08em;
  color: #FFF3E4;
  text-shadow: 0px 3.57333px 9.82667px #390323;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartoon-button_cartoonButton__CPQUc[disabled] {
  opacity: 0.5;
}

.cartoon-button_cartoonButton__CPQUc:hover {
  color: #FFF3E4;
}

.find-arena_wrapper__LqY-Y {
  display: grid;
  grid-template-columns: 5fr 9fr;
  grid-gap: 90px;
  padding: 88px 72px 172px;
  background-image: radial-gradient(108.77% 108.77% at 48.99% 0%, #140B00 10.84%, rgba(0, 0, 0, 0) 51.02%, #140B00 100%),
  url(/static/media/find-arena-bg.dfe44ac9.png);
  align-items: center;
}

.find-arena_blockchains__3bn6B h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.find-arena_blockchainList__G3lTD {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 32px 0;
  gap: 32px 0;
  margin-top: 16px;
  justify-content: space-between;
  width: 100%;
}

.find-arena_blockchainList__G3lTD > * {
  width: 50%;
}

.find-arena_blockchainList__G3lTD > *:nth-child(even) img {
  margin-left: auto;
  display: block;
}

.find-arena_blockchainList__G3lTD > * img {
  width: 80px;
}

.find-arena_wrapper__LqY-Y a {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
}

.find-arena_wrapper__LqY-Y > img {
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .find-arena_wrapper__LqY-Y {
    grid-template-columns: 1fr 1fr;
  }
}


@media (max-width: 1200px) {
  .find-arena_wrapper__LqY-Y a {
    display: unset;
  }

  .find-arena_wrapper__LqY-Y {
    grid-template-columns: 1fr;
  }

  .find-arena_wrapper__LqY-Y > img {
    width: 100%;
  }
  .find-arena_wrapper__LqY-Y img {
    grid-row: 1;
  }

  .find-arena_blockchainList__G3lTD {
    margin: 16px -12px 0;
  }

  .find-arena_blockchainList__G3lTD > * {
    width: auto;
    margin: 0 12px;
  }
}

@media (max-width: 500px) {
  .find-arena_blockchains__3bn6B h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 2px;
  }

  .find-arena_blockchainList__G3lTD {
    flex-direction: column;
  }


  .find-arena_blockchainList__G3lTD > div img {
    margin-inline: auto;
    display: block;
  }

  .find-arena_wrapper__LqY-Y {
    padding: 32px 16px 72px;
    grid-gap: 50px;
  }

  .find-arena_blockchainList__G3lTD > * {
    margin: 0 auto;
  }
}

.character-types_wrapper__2DOra {
  padding-block: 68px 82px;
  display: flex;
  flex-direction: column;
  background: #140B00;
}

.character-types_headline__DupXS {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  align-items: center;
  margin-inline: 59px 84px;
  margin-bottom: 80px;
}

.character-types_headline__DupXS h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.character-types_headline__DupXS p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #FFF3E4;
  opacity: 0.6;
}

.character-types_wrapper__2DOra > .character-types_characterType__3tntN + .character-types_characterType__3tntN {
  margin-top: 112px;
}

.character-types_characterType__3tntN {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.character-types_characterType__3tntN.character-types_rtlCharacter__11dBX {
  flex-direction: row-reverse;
}

.character-types_characterType__3tntN h3 {
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.character-types_characterType__3tntN .character-types_info__2IcEW {
  width: 539px;
  margin-inline: 62px;
}

.character-types_price__1D2wl {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 3px;
  color: #140B00;
  background: #FFF3E4;
  border-radius: 60px;
  padding: 8px 24px;
  margin-top: 16px;
  width: -webkit-max-content;
  width: max-content;
}

.character-types_description__5evdx {
  margin-block: 32px;
  display: flex;
  flex-direction: column;
}

.character-types_description__5evdx > * + * {
  margin-top: 16px;
}

.character-types_descriptionRow__3ZAbL {
  display: flex;
  align-items: center;
}

.character-types_descriptionRow__3ZAbL span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #FFF3E4;
  opacity: 0.6;
}

.character-types_descriptionRow__3ZAbL img {
  margin-right: 12px;
  height: -webkit-max-content;
  height: max-content;
}

.character-types_characterType__3tntN a {
  width: 277px;
  height: 75px;
}

.character-types_mobileImage__35upj {
  display: none;
}

@media (max-width: 1200px) {
  .character-types_characterType__3tntN > img {
    width: 50%;
  }
}

@media (max-width: 1000px) {
  .character-types_headline__DupXS {
    grid-template-columns: 1fr;
    grid-gap: 0;
    /*margin-inline: 0;*/
  }

  .character-types_headline__DupXS h2 {
    font-size: 36px;
    line-height: 48px;
  }

  .character-types_headline__DupXS p {
    font-size: 16px;
    line-height: 24px;
  }

  .character-types_characterType__3tntN {
    flex-direction: column;
    margin-inline: 84px;
  }

  .character-types_characterType__3tntN.character-types_rtlCharacter__11dBX {
    flex-direction: column;
  }

  .character-types_characterType__3tntN .character-types_info__2IcEW {
    width: 100%;
  }

  .character-types_mobileImage__35upj {
    display: block;
    width: 65%;
    margin-left: -84px;
  }

  .character-types_description__5evdx {
    margin-top: 0;
  }

  .character-types_characterType__3tntN > img {
    display: none;
  }

  .character-types_headline__DupXS p {
    margin-top: 8px;
  }
}

@media (max-width: 750px) {
  .character-types_mobileImage__35upj {
    width: 100%;
  }

  .character-types_headline__DupXS {
    margin-bottom: 32px;
  }
}


@media (max-width: 600px) {
  .character-types_mobileImage__35upj {
    margin-inline: -84px;
    width: calc(100% + 168px);
  }
}


@media (max-width: 500px) {
  .character-types_headline__DupXS {
    margin-inline: 16px;
    margin-bottom: 42px;
  }

  .character-types_headline__DupXS h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
  }

  .character-types_headline__DupXS p {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
  }

  .character-types_mobileImage__35upj {
    margin-inline: -16px;
    width: calc(100% + 32px);
  }

  .character-types_info__2IcEW {
    margin-inline: 0;
    width: 100%;
  }

  .character-types_characterType__3tntN {
    flex-direction: column;
    margin-inline: 16px;
  }

  .character-types_descriptionRow__3ZAbL span {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
  }

  .character-types_characterType__3tntN .character-types_price__1D2wl {
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
  }

  .character-types_characterType__3tntN h3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  }
}

.newsletter_wrapper__1YkkD {
  padding: 70px;
  background: #140B00;
}

.newsletter_wrapper__1YkkD > h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.newsletter_wrapper__1YkkD .newsletter_subscribe__KZ98D {
  width: 662px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 32px auto 0;
}

.newsletter_wrapper__1YkkD .newsletter_subscribe__KZ98D input {
  padding: 30px 24px;
  background: rgba(255, 243, 228, 0.2);
  border-radius: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: rgba(255, 243, 228, 0.9);
  border: none;
}

.newsletter_wrapper__1YkkD .newsletter_subscribe__KZ98D button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 16px;
  width: 277px;
  height: 75px;
}

@media (max-width: 800px) {
  .newsletter_wrapper__1YkkD .newsletter_subscribe__KZ98D {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .newsletter_wrapper__1YkkD {
    padding-inline: 16px;
  }
}


@media (max-width: 650px) {
  .newsletter_wrapper__1YkkD {
    padding-inline: 16px;
  }
}


@media (max-width: 600px) {
  .newsletter_wrapper__1YkkD .newsletter_subscribe__KZ98D {
    display: flex;
  }

  .newsletter_wrapper__1YkkD .newsletter_subscribe__KZ98D button {
    position: relative;
    top: unset;
    right: unset;
    -webkit-transform: unset;
            transform: unset;
    margin: 24px auto 0;
  }
}


.earn-more_earnMoreWrapper__bBpJJ {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
  grid-gap: 32px;
}

.earn-more_earnMoreItem__3W_X1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  padding: 0 62px 64px;
}

.earn-more_earnMoreItem__3W_X1 .earn-more_description__1tlhc {
  margin-block: 42px;
}

.earn-more_earnMoreItem__3W_X1 .earn-more_price__2kwYQ {
  margin-top: 8px;
}

.earn-more_earnMoreItem__3W_X1 h3 {
  min-height: 100px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.earn-more_earnMoreItem__3W_X1 > div + div {
  margin-top: 32px;
}

.earn-more_earnMoreItem__3W_X1 .earn-more_descriptionRow__2yBRu img {
  margin-right: 12px;
  height: -webkit-max-content;
  height: max-content;
}

.earn-more_earnMoreItem__3W_X1 > img {
  width: 70%;
  object-fit: cover;
}

.earn-more_earnMoreItem__3W_X1 a {
  margin-top: auto;
  max-width: 277px;
  width: 100%;
  height: 75px;
}

@media (max-width: 785px) {
  .earn-more_earnMoreItem__3W_X1 > img {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .earn-more_earnMoreItem__3W_X1 > img {
    width: 100%;
  }

  .earn-more_earnMoreItem__3W_X1 {
    padding-inline: 16px;
  }
}

.our-support_wrapper__1aK8G {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #140B00;
}

.our-support_wrapper__1aK8G > img {
  width: 100%;
}

.our-support_contentWrapper__171c0 {
  display: flex;
  flex-direction: column;
  padding: 4px 62px;
}

.our-support_contentWrapper__171c0 h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFF3E4;
  margin-bottom: 32px;
}

.our-support_supportList__1k2X1 {
  max-width: 1100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -16px -28px;
  align-items: center;
}

.our-support_supportList__1k2X1 img {
  margin: 16px 28px;
  max-height: 87px;
  max-width: 180px;
}

.our-support_wrapper__1aK8G .our-support_mobileImg__2n1Xx {
  display: none;
}

@media (max-width: 500px) {
  .our-support_contentWrapper__171c0 {
    padding-inline: 16px;
  }
  .our-support_contentWrapper__171c0 h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 2px;
  }
  .our-support_wrapper__1aK8G > img:not(.our-support_mobileImg__2n1Xx) {
    display: none;
  }
  .our-support_wrapper__1aK8G .our-support_mobileImg__2n1Xx {
    display: block;
  }

  .our-support_supportList__1k2X1 img {
    zoom: .7;
    margin: 24px 0;
  }
  .our-support_supportList__1k2X1 {
    margin: 0;
  }
  .our-support_supportList__1k2X1 a {
    display: flex;
  }
  .our-support_supportList__1k2X1 img {
    margin: 0;
  }
}

.news_wrapper__1oDcn {
  padding: 56px 62px;
  display: flex;
  flex-direction: column;
  background: #140B00;
}

.news_wrapper__1oDcn h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.news_newsWrapper__34Ko9 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-top: 48px;
}

.news_newsItem__29ZlW {
  display: flex;
  flex-direction: column;
}

.news_newsItem__29ZlW img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.news_newsItem__29ZlW .news_title__1E76q {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  color: #FFF3E4;
  margin-top: 30px;
}

.news_newsItem__29ZlW .news_description__3BRtU {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #FFF3E4;
  margin-top: 8px;
}

.news_newsItem__29ZlW a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #FFF3E4;
  margin-top: 30px;
}

@media (max-width: 500px) {
  .news_wrapper__1oDcn {
    padding-inline: 16px;
  }
  .news_wrapper__1oDcn h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 2px;
  }
}

.create-hero_wrapper__1R64u {
  display: flex;
  flex-direction: column;
  padding: 117px 62px 72px;
  background: url(/static/media/hero-forest-bg.4a505b34.png), #140B00;
  background-position: center;
  background-size: cover;
}

.create-hero_headline__QAsyX {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  align-items: center;
  margin-bottom: 80px;
}

.create-hero_headline__QAsyX h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.create-hero_headline__QAsyX p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #FFF3E4;
  opacity: 0.6;
}

.create-hero_characterView__nbNQw {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 64px;
}

.create-hero_gearSelection__3ESQC {
  display: grid;
  grid-template-columns: 97px minmax(0, 1fr);
  grid-gap: 12px;
}

.create-hero_bodyPartSelection__19QiL {
  display: flex;
  flex-direction: column;
}

.create-hero_bodyPartSelection__19QiL > * + * {
  margin-top: 16px;
}

.create-hero_bodyPartSelection__19QiL .create-hero_bodyPart__2xEb_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10.5px 24px;
  width: 97px;
  height: 97px;
  cursor: pointer;
  position: relative;
}

.create-hero_bodyPartSelection__19QiL .create-hero_bodyPart__2xEb_ > img:not(.create-hero_bg__iJNhB) {
  width: 49px;
  height: 49px;
}

.create-hero_itemSelection__8kk2f .create-hero_item__4EbV9 .create-hero_bg__iJNhB,
.create-hero_bodyPartSelection__19QiL .create-hero_bodyPart__2xEb_ .create-hero_bg__iJNhB {
  position: absolute;
  inset: 0;
  object-fit: cover;
  transition: .3s;
  opacity: .5;
}

.create-hero_bodyPartSelection__19QiL .create-hero_bodyPart__2xEb_ span,
.create-hero_bodyPartSelection__19QiL .create-hero_bodyPart__2xEb_ img:not(.create-hero_bg__iJNhB) {
  mix-blend-mode: overlay;
  transition: .3s;
}

.create-hero_itemSelection__8kk2f .create-hero_item__4EbV9 .create-hero_color__1ScPX {
  border: 4px white solid;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-top: -12px;
  margin-right: -6px;
  position: relative;
}

.create-hero_bodyPart__2xEb_ span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #FFF3E4;
  white-space: nowrap;
}

.create-hero_itemSelection__8kk2f .create-hero_item__4EbV9.create-hero_activeItem__2xSWU .create-hero_bg__iJNhB,
.create-hero_bodyPartSelection__19QiL .create-hero_bodyPart__2xEb_.create-hero_activeBodyPart__32tnX .create-hero_bg__iJNhB {
  opacity: 1;
}

.create-hero_bodyPartSelection__19QiL .create-hero_bodyPart__2xEb_.create-hero_activeBodyPart__32tnX img:not(.create-hero_bg__iJNhB),
.create-hero_bodyPartSelection__19QiL .create-hero_bodyPart__2xEb_.create-hero_activeBodyPart__32tnX span {
  z-index: 1;
  mix-blend-mode: unset;
}

.create-hero_activeBodyPart__32tnX span {
  color: white;
}

.create-hero_bodyItemsWrapper__1Ymss {
  display: flex;
  flex-direction: column;
}

.create-hero_levelSelection__2aHCu {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  margin-bottom: 12px;
  overflow: auto;
}

.create-hero_level__2J5Z0 {
  height: 75px;
  min-width: 161px;
  background-size: cover;
  opacity: 0.4;
  transition: opacity .3s;
}

.create-hero_activeLevel__1hkxg {
  opacity: 1;
}

.create-hero_itemSelection__8kk2f {
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  grid-auto-rows: 155px;
  grid-gap: 18px 24px;
  height: 600px;
  overflow: auto;
}

.create-hero_itemSelection__8kk2f .create-hero_item__4EbV9 {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-hero_itemSelection__8kk2f .create-hero_item__4EbV9 .create-hero_itemIcon__3i--E {
  position: absolute;
  padding-bottom: 24px;
  z-index: 1;
  width: 100%;
  height: 100%;
}


.create-hero_previewCharacter__3qHAh {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-hero_previewCharacter__3qHAh button {
  width: 277px;
  height: 75px;
  margin-top: 45px;
}

.create-hero_editView__UIZ3M {
  margin-bottom: 45px;
  display: flex;
  align-items: center;
}

.create-hero_editView__UIZ3M > span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #FFF3E4;
}

.create-hero_editView__UIZ3M label {
  margin-inline: 24px;
}

.create-hero_character__2F5rO {
  margin-top: 92px;
  width: 100%;
  max-width: 452px;
  height: 452px;
  position: relative;
}

.create-hero_character__2F5rO .create-hero_color__1ScPX {
  position: absolute;
  z-index: 2;
  inset: 0;
  border-radius: 20px;
  transition: .3s background-color;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.create-hero_character__2F5rO .create-hero_eyes__1llVj,
.create-hero_character__2F5rO .create-hero_facial_hair__2dZ_s,
.create-hero_character__2F5rO .create-hero_eyebrows__1yHbX,
.create-hero_character__2F5rO .create-hero_hair__yspVU,
.create-hero_character__2F5rO .create-hero_clothes__1O3Sb,
.create-hero_character__2F5rO .create-hero_extra__ZKSHs,
.create-hero_character__2F5rO .create-hero_head__39shy,
.create-hero_character__2F5rO .create-hero_hero__1ARLl {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 64px);
  object-fit: contain;
  -webkit-transform: translate(-35%, -35%);
          transform: translate(-35%, -35%);
  scale: 1.4;
}

.create-hero_character__2F5rO .create-hero_hero__1ARLl {
  z-index: 3;
}

.create-hero_character__2F5rO .create-hero_eyes__1llVj,
.create-hero_character__2F5rO .create-hero_facial_hair__2dZ_s,
.create-hero_character__2F5rO .create-hero_eyebrows__1yHbX,
.create-hero_character__2F5rO .create-hero_hair__yspVU,
.create-hero_character__2F5rO .create-hero_clothes__1O3Sb,
.create-hero_character__2F5rO .create-hero_extra__ZKSHs,
.create-hero_character__2F5rO .create-hero_head__39shy {
  z-index: 4;
}

.create-hero_character__2F5rO .create-hero_facial_hair__2dZ_s {
  z-index: 5;
}

.create-hero_character__2F5rO .create-hero_hair__yspVU {
  z-index: 6;
}

.create-hero_gearSelection__3ESQC > button {
  display: none;
}

@media (max-width: 1100px) {
  .create-hero_headline__QAsyX {
    grid-template-columns: minmax(0, 1fr);
  }

  .create-hero_characterView__nbNQw {
    grid-template-columns: minmax(0, 1fr);
  }

  .create-hero_previewCharacter__3qHAh {
    grid-row: 1;
  }

  .create-hero_character__2F5rO {
    width: 50%;
    padding-bottom: 50%;
  }

  .create-hero_gearSelection__3ESQC > button {
    display: block;
    grid-column: 1/-1;
    width: 277px;
    height: 75px;
    margin: 36px auto 0;
  }

  .create-hero_previewCharacter__3qHAh > button {
    display: none;
  }
}

@media (max-width: 650px) {
  .create-hero_wrapper__1R64u {
    padding: 117px 16px 72px;
  }

  .create-hero_characterView__nbNQw {
    grid-gap: 40px;
  }

  .create-hero_gearSelection__3ESQC {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 32px;
  }

  .create-hero_bodyPartSelection__19QiL {
    flex-direction: row;
    overflow: auto;
    margin-right: -16px;
    max-width: calc(100vw - 32px);
  }

  .create-hero_character__2F5rO {
    width: 75%;
    padding-bottom: 75%;
  }

  .create-hero_bodyPart__2xEb_ {
    margin-top: 0;
    margin-right: 16px;
  }

  .create-hero_itemColorSelection__jipvK {
    margin-inline: 0;
    overflow: auto;
    max-width: calc(100vw - 32px);
  }

  .create-hero_itemSelection__8kk2f {
    display: flex;
    overflow: auto;
    width: 100%;
    height: 170px;
    max-width: calc(100vw - 32px);
  }
  .create-hero_itemSelection__8kk2f .create-hero_item__4EbV9 {
    min-width: 163px;
  }
}


@media (max-width: 500px) {
  .create-hero_character__2F5rO {
    width: 100%;
    padding-bottom: 91%;
  }

  .create-hero_headline__QAsyX h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 2px;
  }

  .create-hero_headline__QAsyX p {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #FFF3E4;
  }
}

.styles_headerWrapper__Nu5xq {
  height: 89px;
  background: #140B00;
  -webkit-backdrop-filter: blur(28.5px);
          backdrop-filter: blur(28.5px);
  position: fixed;
  inset: 0 0 auto 0;
  padding: 12px 56px;
  display: flex;
  align-items: center;
  z-index: 100;
  transition: .3s;
}

.styles_mediaLinks__XwB-W {
  display: flex;
  min-width: 180px;
}

.styles_mediaLinks__XwB-W > *,
.styles_social__KmMAU > * {
  cursor: pointer;
}

.styles_mediaLinks__XwB-W > * + * {
  margin-left: 25px;
}

.styles_headerWrapper__Nu5xq .styles_navigation__3Z3Q7 {
  display: flex;
  margin-inline: 56px 80px;
  width: 100%;
}

.styles_navigation__3Z3Q7 a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #FFF3E4;
}

.styles_navigation__3Z3Q7 > img {
  position: absolute;
  top: 19px;
  left: 50%;
  width: 124px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.styles_headerWrapper__Nu5xq  .styles_navigation__3Z3Q7 > a + a {
  margin-left: 36px;
}

.styles_headerWrapper__Nu5xq > a button {
  padding: 22px 20px;
  white-space: nowrap;
}

.styles_mobileHeaderWrapper__rSYFV {
  display: none;
}

.styles_burgerMenu__QF5Ce > div {
  width: 24px;
  height: 2px;
  background: #FFF3E4;
  margin-bottom: 4px;
}

.styles_burgerMenuContent__KMzl8 {
  display: none;
}

@media (max-width: 1250px) {
  .styles_headerWrapper__Nu5xq {
    padding: 12px 24px;
  }

  .styles_headerWrapper__Nu5xq .styles_navigation__3Z3Q7 {
    margin-inline: 24px 40px;
  }

  .styles_headerWrapper__Nu5xq .styles_navigation__3Z3Q7 > a + a {
    margin-left: 24px;
  }

  .styles_headerWrapper__Nu5xq > a button {
    padding: 22px 16px;
  }
}

@media (max-width: 1050px) {
  .styles_headerWrapper__Nu5xq.styles_activeMenu__2BiMU {
    background-color: transparent;
    -webkit-backdrop-filter: unset;
            backdrop-filter: unset
  }
  .styles_headerWrapper__Nu5xq .styles_navigation__3Z3Q7 {
    display: none;
  }

  .styles_headerWrapper__Nu5xq > a button {
    display: none;
  }

  .styles_mediaLinks__XwB-W {
    display: none;
  }

  .styles_headerWrapper__Nu5xq {
    padding: 12px 24px;
    height: 48px;
  }

  .styles_mobileHeaderWrapper__rSYFV > img {
    position: absolute;
    top: 13px;
    left: 50%;
    z-index: 100;
    height: 97px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .styles_burgerMenu__QF5Ce {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    z-index: 100;
  }

  .styles_burgerMenu__QF5Ce > * {
    transition: .3s;
  }
  .styles_burgerMenu__QF5Ce > * + * {
    margin-top: 5px;
  }

  .styles_mobileHeaderWrapper__rSYFV {
    display: flex;
    align-items: center;
    width: 200%;
  }

  .styles_burgerMenuContent__KMzl8 {
    position: fixed;
    inset: 0;
    background: #140B00;
    -webkit-backdrop-filter: blur(28.5px);
            backdrop-filter: blur(28.5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: .3s;
    background: url(/static/media/burger-content-bg.f947ae56.png) no-repeat center;
    background-size: cover;
  }

  .styles_activeMenu__2BiMU.styles_burgerMenuContent__KMzl8 {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

/*  active styles for burger menu with 2 lines to make an x form*/
  .styles_activeMenu__2BiMU .styles_burgerMenu__QF5Ce > *:nth-child(1) {
    -webkit-transform: rotate(45deg) translateY(6px);
            transform: rotate(45deg) translateY(6px);
  }

  .styles_activeMenu__2BiMU .styles_burgerMenu__QF5Ce > *:nth-child(2) {
    -webkit-transform: rotate(-45deg) translateY(-6px);
            transform: rotate(-45deg) translateY(-6px);
  }

  .styles_activeMenu__2BiMU .styles_burgerMenu__QF5Ce > * + * {
    margin-top: 0;
  }

  .styles_burgerMenuContent__KMzl8 > a button {
    width: 320px;
    height: 86px;
    margin-top: 150px;
  }

  .styles_burgerMenuContent__KMzl8 .styles_navigation__3Z3Q7 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 63px;
  }

  .styles_burgerMenuContent__KMzl8 .styles_navigation__3Z3Q7 a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    color: #FFF3E4;
    margin-top: 32px;
  }

  .styles_burgerMenuContent__KMzl8 .styles_social__KmMAU {
    max-width: 200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 52px;
  }
}

.styles_wrapper__3aC4K {
  background: #140B00;
  position: relative;
  padding: 40px 48px;
  display: flex;
  flex-direction: column;
}

.styles_footerTop__3CpLY {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.styles_mediaLinks__RlydB {
  display: flex;
}

.styles_mediaLinksMobile__3KDz8 > * + *,
.styles_mediaLinks__RlydB > * + * {
  margin-left: 25px;
}

.styles_navigationLaptop__3Qkjv,
.styles_navigation__3ov4s {
  display: flex;
  margin-inline: 56px 80px;
  width: 100%;
}

.styles_navigationLaptop__3Qkjv a,
.styles_navigation__3ov4s a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #FFF3E4;
}

.styles_navigation__3ov4s > img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 124px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.styles_navigation__3ov4s > a + a {
  margin-left: 36px;
}

.styles_footerTop__3CpLY > a button {
  padding: 22px 20px;
  white-space: nowrap;
}

.styles_copyright__gc2fx {
  display: block;
  margin-top: 32px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #FFF3E4;
}

.styles_mediaLinksMobile__3KDz8,
.styles_navigationLaptop__3Qkjv {
  display: none;
}

.styles_mediaLinksMobile__3KDz8 {
  margin-top: 62px;
  margin-inline: auto;
}

.styles_navigationLaptop__3Qkjv {
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .styles_navigationLaptop__3Qkjv {
    margin-inline: 0;
    display: flex;
  }

  .styles_navigation__3ov4s a {
    display: none;
  }
}

@media (max-width: 800px) {
  .styles_mediaLinksMobile__3KDz8 {
    display: flex;
  }

  .styles_mediaLinks__RlydB {
    display: none;
  }

  .styles_copyright__gc2fx {
    margin-top: 62px;
  }

  .styles_footerTop__3CpLY {
    display: block;
  }

  .styles_footerTop__3CpLY > a button {
    margin-top: 164px;
    margin-inline: auto;
    display: block;
  }

  .styles_navigationLaptop__3Qkjv {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 62px;
  }

  .styles_navigationLaptop__3Qkjv > * + * {
    margin-top: 32px;
  }
}

