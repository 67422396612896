.wrapper {
  background: #140B00;
  position: relative;
  padding: 40px 48px;
  display: flex;
  flex-direction: column;
}

.footerTop {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.mediaLinks {
  display: flex;
}

.mediaLinksMobile > * + *,
.mediaLinks > * + * {
  margin-left: 25px;
}

.navigationLaptop,
.navigation {
  display: flex;
  margin-inline: 56px 80px;
  width: 100%;
}

.navigationLaptop a,
.navigation a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #FFF3E4;
}

.navigation > img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 124px;
  transform: translateX(-50%);
}

.navigation > a + a {
  margin-left: 36px;
}

.footerTop > a button {
  padding: 22px 20px;
  white-space: nowrap;
}

.copyright {
  display: block;
  margin-top: 32px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #FFF3E4;
}

.mediaLinksMobile,
.navigationLaptop {
  display: none;
}

.mediaLinksMobile {
  margin-top: 62px;
  margin-inline: auto;
}

.navigationLaptop {
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .navigationLaptop {
    margin-inline: 0;
    display: flex;
  }

  .navigation a {
    display: none;
  }
}

@media (max-width: 800px) {
  .mediaLinksMobile {
    display: flex;
  }

  .mediaLinks {
    display: none;
  }

  .copyright {
    margin-top: 62px;
  }

  .footerTop {
    display: block;
  }

  .footerTop > a button {
    margin-top: 164px;
    margin-inline: auto;
    display: block;
  }

  .navigationLaptop {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 62px;
  }

  .navigationLaptop > * + * {
    margin-top: 32px;
  }
}
