.wrapper {
  padding-block: 68px 82px;
  display: flex;
  flex-direction: column;
  background: #140B00;
}

.headline {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  align-items: center;
  margin-inline: 59px 84px;
  margin-bottom: 80px;
}

.headline h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.headline p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #FFF3E4;
  opacity: 0.6;
}

.wrapper > .characterType + .characterType {
  margin-top: 112px;
}

.characterType {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.characterType.rtlCharacter {
  flex-direction: row-reverse;
}

.characterType h3 {
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.characterType .info {
  width: 539px;
  margin-inline: 62px;
}

.price {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 3px;
  color: #140B00;
  background: #FFF3E4;
  border-radius: 60px;
  padding: 8px 24px;
  margin-top: 16px;
  width: max-content;
}

.description {
  margin-block: 32px;
  display: flex;
  flex-direction: column;
}

.description > * + * {
  margin-top: 16px;
}

.descriptionRow {
  display: flex;
  align-items: center;
}

.descriptionRow span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #FFF3E4;
  opacity: 0.6;
}

.descriptionRow img {
  margin-right: 12px;
  height: max-content;
}

.characterType a {
  width: 277px;
  height: 75px;
}

.mobileImage {
  display: none;
}

@media (max-width: 1200px) {
  .characterType > img {
    width: 50%;
  }
}

@media (max-width: 1000px) {
  .headline {
    grid-template-columns: 1fr;
    grid-gap: 0;
    /*margin-inline: 0;*/
  }

  .headline h2 {
    font-size: 36px;
    line-height: 48px;
  }

  .headline p {
    font-size: 16px;
    line-height: 24px;
  }

  .characterType {
    flex-direction: column;
    margin-inline: 84px;
  }

  .characterType.rtlCharacter {
    flex-direction: column;
  }

  .characterType .info {
    width: 100%;
  }

  .mobileImage {
    display: block;
    width: 65%;
    margin-left: -84px;
  }

  .description {
    margin-top: 0;
  }

  .characterType > img {
    display: none;
  }

  .headline p {
    margin-top: 8px;
  }
}

@media (max-width: 750px) {
  .mobileImage {
    width: 100%;
  }

  .headline {
    margin-bottom: 32px;
  }
}


@media (max-width: 600px) {
  .mobileImage {
    margin-inline: -84px;
    width: calc(100% + 168px);
  }
}


@media (max-width: 500px) {
  .headline {
    margin-inline: 16px;
    margin-bottom: 42px;
  }

  .headline h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
  }

  .headline p {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
  }

  .mobileImage {
    margin-inline: -16px;
    width: calc(100% + 32px);
  }

  .info {
    margin-inline: 0;
    width: 100%;
  }

  .characterType {
    flex-direction: column;
    margin-inline: 16px;
  }

  .descriptionRow span {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
  }

  .characterType .price {
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
  }

  .characterType h3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  }
}
