.wrapper {
  padding: 56px 62px;
  display: flex;
  flex-direction: column;
  background: #140B00;
}

.wrapper h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.newsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-top: 48px;
}

.newsItem {
  display: flex;
  flex-direction: column;
}

.newsItem img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.newsItem .title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  color: #FFF3E4;
  margin-top: 30px;
}

.newsItem .description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #FFF3E4;
  margin-top: 8px;
}

.newsItem a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #FFF3E4;
  margin-top: 30px;
}

@media (max-width: 500px) {
  .wrapper {
    padding-inline: 16px;
  }
  .wrapper h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 2px;
  }
}
