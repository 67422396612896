.wrapper {
  display: grid;
  grid-template-columns: 5fr 9fr;
  grid-gap: 90px;
  padding: 88px 72px 172px;
  background-image: radial-gradient(108.77% 108.77% at 48.99% 0%, #140B00 10.84%, rgba(0, 0, 0, 0) 51.02%, #140B00 100%),
  url("../../../assets/image/find-arena-bg.png");
  align-items: center;
}

.blockchains h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 50px;
  letter-spacing: 2px;
  color: #FFF3E4;
}

.blockchainList {
  display: flex;
  flex-wrap: wrap;
  gap: 32px 0;
  margin-top: 16px;
  justify-content: space-between;
  width: 100%;
}

.blockchainList > * {
  width: 50%;
}

.blockchainList > *:nth-child(even) img {
  margin-left: auto;
  display: block;
}

.blockchainList > * img {
  width: 80px;
}

.wrapper a {
  width: max-content;
  display: flex;
}

.wrapper > img {
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }
}


@media (max-width: 1200px) {
  .wrapper a {
    display: unset;
  }

  .wrapper {
    grid-template-columns: 1fr;
  }

  .wrapper > img {
    width: 100%;
  }
  .wrapper img {
    grid-row: 1;
  }

  .blockchainList {
    margin: 16px -12px 0;
  }

  .blockchainList > * {
    width: auto;
    margin: 0 12px;
  }
}

@media (max-width: 500px) {
  .blockchains h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 2px;
  }

  .blockchainList {
    flex-direction: column;
  }


  .blockchainList > div img {
    margin-inline: auto;
    display: block;
  }

  .wrapper {
    padding: 32px 16px 72px;
    grid-gap: 50px;
  }

  .blockchainList > * {
    margin: 0 auto;
  }
}
