.cartoonButton {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.08em;
  color: #FFF3E4;
  text-shadow: 0px 3.57333px 9.82667px #390323;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartoonButton[disabled] {
  opacity: 0.5;
}

.cartoonButton:hover {
  color: #FFF3E4;
}
